import { assetList } from '@/api/product/category'

const stock = {
  state: {
    isManage: false,
    categoryId: 0,
    beginUpdate: new Date(),
    mapType: '',
    stations: [],
    searchCondition: {},
    allowAssetList: [],
    showAssets: [],
    allStationList: [],
    searchRange: 200
  },
  mutations: {
    set_stock_isManage (state, data) {
      state.isManage = data
    },
    set_stock_update (state, data) {
      state.beginUpdate = data
    },
    set_stock_stations (state, data) {
      state.stations = data
    },
    set_stock_map_type (state, data) {
      state.mapType = data
    },
    set_stock_search_condition (state, data) {
      state.searchCondition = data
    },
    set_stock_asset_list (state, data) {
      state.allowAssetList = data
    },
    set_stock_categoryId (state, data) {
      state.categoryId = data
    },
    set_stock_show_assets (state, data) {
      state.showAssets = data
    },
    set_stock_station_list (state, data) {
      state.allStationList = data
    },
    set_stock_search_range (state, data) {
      state.searchRange = data
    }
  },
  getters: {

  },
  actions: {
    // 获取租户下可用的资产清单
    getAllowAssetList ({ state, commit }, query) {
      return new Promise((resolve) => {
        if (state.allowAssetList.length === 0) {
          const postData = { publisherId: query.publisherId }
          assetList(postData).then(res => {
            commit('set_stock_asset_list', res)
            resolve(res)
          })
        } else { // 从缓存中提取
          resolve(state.allowAssetList)
        }
      })
    }

  }
}

export default stock
