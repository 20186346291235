import request from '@/utils/requestV2'
const qs = require('qs')

// 根据类别和媒介主获取资产
export function assetList (data) {
  return request({
    url: '/ooh-product/v1/category/assetlist',
    method: 'POST',
    data: qs.stringify(data)
  })
}
/**
 * 获取媒介主的产品类目
 * @param {Object} data
 */
export function getCategoryLists (data) {
  return request({
    url: '/ooh-product/v1/category/getcategorylist',
    method: 'POST',
    data: qs.stringify(data)
  })
}

/**
 * 获取全部类目
 * @param {Object} data
 */
export function allCateory (data) {
  return request({
    url: '/ooh-product/v1/category/all',
    method: 'POST',
    data: qs.stringify(data)
  })
}
