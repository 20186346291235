<template>
  <div>
    <container>
      <div slot="left_box_content">
        <component :is="curLeftComponent" @on-change="handleChangeCategory"></component>
      </div>
      <div slot="right_box_content">
        <component :is="curRightComponent" ></component>
      </div>
      <div slot="bottom_box_content">
        <component :is="curBottomComponent" ></component>
      </div>
      <div slot="bg_map">
        <component :is="curMapComponent" ></component>
      </div>

    </container>
  </div>
</template>

<script>
import Container from '@/components/common/Container'
import { sysMixins } from '@/assets/mixins/sys'
// 引入store模块
import stockStoreModule from '@/store/modules/stock'

export default {
  mixins: [sysMixins],
  props: {
    isManage: {
      type: Boolean,
      required: true
    }
  },
  components: {
    Container,
    leftComponent: () => import('./components/Left'),
    rightComponent: () => import('./components/Right'),
    bottomComponent: () => import('./components/Bottom'),
    centerComponent: () => import('./components/Center')
  },
  created () {
    // 注册动态store模块
    if (!this.$store.hasModule('stock')) {
      this.$store.registerModule('stock', stockStoreModule)
      this.$store.commit('set_stock_isManage', this.isManage)
    }
  },
  mounted () {
    this.setShowLeftBlock(true)
    this.setLeftComponent('leftComponent')
    this.setShowRightBlock(true)

    // 初始化其他组件部分，由左侧来控制
    this.setBottomComponent('bottomComponent')
    this.setShowRightBlock(false)
    this.setBottomHeight('90%')
    this.setMapComponent('')
  },
  methods: {
    handleChangeCategory (val) {
      if (val) {
        this.setRightComponent('rightComponent')
        this.setShowRightBlock(true)
        this.setBottomHeight('300px')
        this.setMapComponent('centerComponent')
      } else {
        this.setRightComponent('')
        this.setShowRightBlock(false)
        this.setBottomHeight('90%')
        this.setMapComponent('')
      }
    }
  },
  destroyed () {
    // 卸载installSetting动态store模块
    this.$store.commit('set_stock_show_assets', [])

    this.$store.unregisterModule('stock')
    this.setShowLeftBlock(true)
    this.setShowMap(true)
    this.setShowRightBlock(true)
    this.setBottomHeight('300px')
    this.setLeftComponent('')
    this.setRightComponent('')
    this.setBottomComponent('')
    this.setMapComponent('')
  }
}
</script>
